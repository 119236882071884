import React from "react"
import { Container, Typography } from "@mui/material"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => {

    return (
        <Layout>
            <Container>
                <Typography variant="h4" marginTop="64px" marginBottom="24px">Bogie Studios LLC Privacy Policy</Typography>
                <Typography variant="body1">Your privacy is important to us. It is Bogie Studios LLC&#39;s policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including via our app, Greens, and its associated services. </Typography>
                <Typography variant="body1">Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use an app or online service. </Typography>
                <Typography variant="body1">In the event our app contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our app. </Typography>
                <Typography variant="body1">This policy is effective as of 28 October 2022. </Typography>
                <Typography variant="body1">Last updated: 28 October 2022 </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Information We Collect</Typography>
                <Typography variant="body1">Information we collect falls into one of two categories: &ldquo;voluntarily provided&rdquo; information and &ldquo;automatically collected&rdquo; information. </Typography>
                <Typography variant="body1">&ldquo;Voluntarily provided&rdquo; information refers to any information you knowingly and actively provide us when using our app and its associated services. </Typography>
                <Typography variant="body1">&ldquo;Automatically collected&rdquo; information refers to any information automatically sent by your device in the course of accessing our app and its associated services. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Log Data</Typography>
                <Typography variant="body1">When you access our servers via our app, we may automatically log the standard data provided by your device. It may include your device's Internet Protocol (IP) address, your device type and version, your activity within the app, time and date, and other details about your usage. </Typography>
                <Typography variant="body1">Additionally, when you encounter certain errors while using the app, we automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is. </Typography>
                <Typography variant="body1">Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Device Data</Typography>
                <Typography variant="body1">Our app may access and collect data via your device's in-built tools, such as: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;Your identity</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Location data</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Camera</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Body sensor</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Contacts</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Storage, photos and/or media</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Notifications</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Background data refresh</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Mobile data</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Bluetooth</Typography>
                </Typography>
                <Typography variant="body1">When you install the app or use your device’s tools within the app, we request permission to access this information. The specific data we collect can depend on the individual settings of your device and the permissions you grant when you install and use the app. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Personal Information</Typography>
                <Typography variant="body1">We may ask for personal information — for example, when you submit content to us or when you contact us — which may include one or more of the following: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;Name</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Email</Typography>
                </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">User-Generated Content</Typography>
                <Typography variant="body1">We consider &ldquo;user-generated content&rdquo; to be materials (text, image and/or video content) voluntarily supplied to us by our users for the purpose of publication on our platform, website or re-publishing on our social media channels. All user-generated content is associated with the account or email address used to submit the materials. </Typography>
                <Typography variant="body1">Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Legitimate Reasons for Processing Your Personal Information</Typography>
                <Typography variant="body1">We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Collection and Use of Information</Typography>
                <Typography variant="body1">We may collect personal information from you when you do any of the following on our website: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;Register for an account</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Use a mobile device or web browser to access our content</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Contact us via email, social media, or on any similar technologies</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;When you mention us on social media</Typography>
                </Typography>
                <Typography variant="body1">We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;to provide you with our app and platform's core features and services</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;to contact and communicate with you</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;for security and fraud prevention, and to ensure that our sites and apps are safe, secure, and used in line with our terms of use</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;for technical assessment, including to operate and improve our app, associated applications, and associated social media platforms</Typography>
                </Typography>
                <Typography variant="body1">We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, If you consent to us accessing your social media profiles, we may combine information sourced from those profiles with information received from you directly to provide you with an enhanced experience of our app and services. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Security of Your Personal Information</Typography>
                <Typography variant="body1">When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification. </Typography>
                <Typography variant="body1">Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. </Typography>
                <Typography variant="body1">You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and confidential. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">How Long We Keep Your Personal Information</Typography>
                <Typography variant="body1">We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we may retain this information for the duration your account exists on our system. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you. </Typography>
                <Typography variant="body1">However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes. </Typography>
                <Typography variant="h5" marginTop="64px" marginBottom="24px">Payments</Typography>
                <Typography variant="body1">We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).</Typography>
                <Typography variant="body1">We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;
                        <Typography variant="body1"><strong>Apple Store In-App Payments</strong></Typography>
                        website/src/pages/terms.tsx                        <Typography variant="body1">Their Privacy Policy can be viewed at <a href="https://www.apple.com/legal/privacy/en-ww/" rel="external nofollow noopener" target="_blank">https://www.apple.com/legal/privacy/en-ww/</a></Typography>
                    </Typography>
                </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Children’s Privacy</Typography>
                <Typography variant="body1">We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Disclosure of Personal Information to Third Parties</Typography>
                <Typography variant="body1">We may disclose personal information to: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;a parent, subsidiary, or affiliate of our company</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing providers, professional advisors, and payment systems operators</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;our employees, contractors, and/or related entities</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;our existing or potential agents or business partners</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;third parties to collect and process data</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;an entity that buys, or to which we transfer all or substantially all of our assets and business</Typography>
                </Typography>
                <Typography variant="body1">Third parties we currently use include: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;Google Analytics</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Mixpanel</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Sendgrid</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Apple Pay</Typography>
                </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">International Transfers of Personal Information</Typography>
                <Typography variant="body1">The personal information we collect is stored and/or processed in United States, or where we or our partners, affiliates, and third-party providers maintain facilities. </Typography>
                <Typography variant="body1">The countries to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Your Rights and Controlling Your Personal Information</Typography>
                <Typography variant="body1"><strong>Your choice:</strong> By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our app or the products and/or services offered on or through it. </Typography>
                <Typography variant="body1"><strong>Information from third parties:</strong> If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us. </Typography>
                <Typography variant="body1"><strong>Marketing permission:</strong> If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. </Typography>
                <Typography variant="body1"><strong>Access:</strong> You may request details of the personal information that we hold about you. </Typography>
                <Typography variant="body1"><strong>Correction:</strong> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date. </Typography>
                <Typography variant="body1"><strong>Non-discrimination:</strong> We will not discriminate against you for exercising any of your rights over your personal information. Unless your personal information is required to provide you with a particular service or offer (for example serving particular content to your device), we will not deny you goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties, or provide you with a different level or quality of goods or services. </Typography>
                <Typography variant="body1"><strong>Downloading of Personal Information:</strong> We provide a means for you to download the personal information you have shared through our app. Please contact us for more information. </Typography>
                <Typography variant="body1"><strong>Notification of data breaches:</strong> We will comply with laws applicable to us in respect of any data breach. </Typography>
                <Typography variant="body1"><strong>Complaints:</strong> If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint. </Typography>
                <Typography variant="body1"><strong>Unsubscribe:</strong> To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities provided in the communication. We may need to request specific information from you to help us confirm your identity. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Business Transfers</Typography>
                <Typography variant="body1">If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy, which they will be required to assume as it is the basis for any ownership or use rights we have over such information. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Limits of Our Policy</Typography>
                <Typography variant="body1">Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Changes to This Policy</Typography>
                <Typography variant="body1">At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here and on our website. </Typography>
                <Typography variant="body1">If the changes are significant, or if required by applicable law, we will contact you (based on your selected preferences for communications from us) and all our registered users with the new details and links to the updated or changed policy. </Typography>
                <Typography variant="body1">If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Additional Disclosures for California Compliance (US)</Typography>
                <Typography variant="body1">Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family, or household purposes, you may ask us about the information we release to other organizations for their marketing purposes. </Typography>
                <Typography variant="body1">To make such a request, please contact us using the details provided in this privacy policy with &ldquo;Request for California privacy information&rdquo; in the subject line. You may make this type of request once every calendar year. We will email you a list of categories of personal information we revealed to other organisations for their marketing purposes in the last calendar year, along with their names and addresses. Not all personal information shared in this way is covered by Section 1798.83 of the California Civil Code. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Do Not Track</Typography>
                <Typography variant="body1">Some browsers have a &ldquo;Do Not Track&rdquo; feature that lets you tell websites that you do not want to have your online activities tracked. At this time, we do not respond to browser &ldquo;Do Not Track&rdquo; signals. </Typography>
                <Typography variant="body1">We adhere to the standards outlined in this privacy policy, ensuring we collect and process personal information lawfully, fairly, transparently, and with legitimate, legal reasons for doing so. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">CCPA-permitted financial incentives</Typography>
                <Typography variant="body1">In accordance with your right to non-discrimination, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels for the goods or services we provide. </Typography>
                <Typography variant="body1">Any CCPA-permitted financial incentive we offer will reasonably relate to the value of your personal information, and we will provide written terms that describe clearly the nature of such an offer. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">California Notice of Collection</Typography>
                <Typography variant="body1">In the past 12 months, we have collected the following categories of personal information enumerated in the California Consumer Privacy Act: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;Identifiers, such as name, email address, phone number account name, IP address, and an ID or number assigned to your account.</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;Geolocation data.</Typography>
                </Typography>
                <Typography variant="body1">For more information on information we collect, including the sources we receive information from, review the &ldquo;Information We Collect&rdquo; section. We collect and use these categories of personal information for the business purposes described in the &ldquo;Collection and Use of Information&rdquo; section, including to provide and manage our Service. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Right to Know and Delete</Typography>
                <Typography variant="body1">If you are a California resident, you have rights to delete your personal information we collected and know certain information about our data practices in the preceding 12 months. In particular, you have the right to request the following from us: </Typography>
                <Typography variant="body1">
                    <Typography variant="body1" marginLeft="32px">&#x2022;The categories of personal information we have collected about you;</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;The categories of sources from which the personal information was collected;</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;The categories of personal information about you we disclosed for a business purpose or sold;</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;The categories of third parties to whom the personal information was disclosed for a business purpose or sold;</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;The business or commercial purpose for collecting or selling the personal information; and</Typography>
                    <Typography variant="body1" marginLeft="32px">&#x2022;The specific pieces of personal information we have collected about you.</Typography>
                </Typography>
                <Typography variant="body1">To exercise any of these rights, please contact us using the details provided in this privacy policy. </Typography>
                <Typography variant="h6" marginTop="32px" marginBottom="24px">Shine the Light</Typography>
                <Typography variant="body1">If you are a California resident, in addition to the rights discussed above, you have the right to request information from us regarding the manner in which we share certain personal information as defined by California’s &ldquo;Shine the Light&rdquo; with third parties and affiliates for their own direct marketing purposes. </Typography>
                <Typography variant="body1">To receive this information, send us a request using the contact details provided in this privacy policy. Requests must include &ldquo;California Privacy Rights Request&rdquo; in the first line of the description and include your name, street address, city, state, and ZIP code. </Typography>
                <Typography variant="h5" marginTop="48px" marginBottom="24px">Contact Us</Typography>
                <Typography variant="body1">For any questions or concerns regarding your privacy, you may contact us using the following details: </Typography>
                <Typography variant="body1">William Irwin<br />
                    https://forms.gle/QaazEccD5CRJ7XDg9 </Typography>
            </Container>
        </Layout>
    )
}

export const Head = () => <SEO
    title="Privacy"
/>

export default PrivacyPage;