import React, { ReactNode } from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

interface Props {
  title: string
  imageUrl?: string
  pathname?: string
  children?: ReactNode
}

const SEO = ({ title, imageUrl, pathname, children }: Props) => {
  const { title: siteTitle, description: defaultDescription, siteUrl, twitterUsername } = useSiteMetadata()

  let pageTitle = `${title} | ${siteTitle}`
  let pageUrl = `${siteUrl}${pathname || ``}`

  let pageImageUrl = imageUrl || 'https://greensgolf.app/name-logo.png';

  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={defaultDescription} />

      <meta name="og:description" content={defaultDescription} />
      <meta name="og:image" content={pageImageUrl} />

      <meta name="twitter:card" content="summary_large_card" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:description" content={defaultDescription} />
      <meta name="twitter:image" content={pageImageUrl} />
      <meta name="twitter:creator" content={twitterUsername} />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Pacifico:wght@400;600&display=swap" rel="stylesheet" />

      <link rel="icon" href="https://greensgolf.app/logo.png" />
    </>
  );
};

export default SEO;